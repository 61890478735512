import React from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { propTypes } from '../../../util/types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ListingCard, NamedLink, IconArrow } from '../../../components';

import css from './SectionFeaturedListings.module.css';

const MINIMUM_LISTING_COUNT = 0;

const SectionFeaturedListings = props => {
  const {
    rootClassName,
    className,
    queryFeaturedListingsInProgress,
    queryFeaturedListingsError,
  } = props;

  const listings = props.featuredListings
    .filter(l => l.attributes.publicData.featured || l.attributes.publicData.featuredOrder)
    // sort by l.attributes.publicData.featuredOrder highest number to lowest, the undefined listing will stay be at the last
    .sort((a, b) =>
      a.attributes.publicData.featuredOrder === undefined
        ? 1
        : b.attributes.publicData.featuredOrder === undefined
        ? -1
        : b.attributes.publicData.featuredOrder - a.attributes.publicData.featuredOrder
    );

  const classes = classNames(rootClassName || css.root, className);

  // Remove SectionFeaturedListings if querying listings is in
  // progress, of if there's any error.
  if (queryFeaturedListingsInProgress || queryFeaturedListingsError) {
    return null;
  }

  const hasListings = listings.length > MINIMUM_LISTING_COUNT;

  return hasListings ? (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFeaturedListings.title" />
      </div>
      <div className={css.listingCards}>
        {listings.map(l => {
          return (
            <ListingCard
              key={l.id.uuid}
              listing={l}
              showAuthorInfo
              rootClassName={css.listingCard}
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

SectionFeaturedListings.defaultProps = {
  rootClassName: null,
  className: null,
  queryFeaturedListingsInProgress: false,
  queryFeaturedListingsError: null,
};

SectionFeaturedListings.propTypes = {
  rootClassName: string,
  className: string,

  listings: arrayOf(propTypes.listing),
  queryFeaturedListingsInProgress: bool.isRequired,
  queryFeaturedListingsError: propTypes.error,
};

export default SectionFeaturedListings;
