import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/sneakertimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionIntro from './SectionIntro/SectionIntro';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import SectionListings from './SectionListings/SectionListings';
import SectionServices from './SectionServices/SectionServices';
import SectionSellOnPetSpace from './SectionSellOnPetspace/SectionSellOnPetspace';
import FullWidthWrapper from './FullWidthWrapper';
import css from './LandingPage.module.css';
import SectionFeaturedListings from './SectionFeaturedListings/SectionFeaturedListings';
import SectionNewBrands from './SectionNewBrands/SectionNewBrands';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, viewport, ...listingProps } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div
                className={classNames(
                  css.sectionContentFirstChild,
                  css.sectionContentFullWidth,
                  css.sectionIntro
                )}
              >
                <FullWidthWrapper>
                  <SectionIntro />
                </FullWidthWrapper>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionFeaturedListings {...listingProps} />
              </div>
            </li>
            <li className={css.section}>
              <div
                className={classNames(
                  css.sectionContent,
                  css.sectionContentFullWidth,
                  css.sectionServices
                )}
              >
                <FullWidthWrapper>
                  <SectionServices {...listingProps} />
                </FullWidthWrapper>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionFilteredSearches viewport={viewport} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionListings {...listingProps} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionNewBrands viewport={viewport} />
              </div>
            </li>
            <li className={css.section}>
              <div
                className={classNames(
                  css.sectionContentLastChild,
                  css.sectionContentFullWidth,
                  css.sectionSellOnPetspace
                )}
              >
                <FullWidthWrapper>
                  <SectionSellOnPetSpace />
                </FullWidthWrapper>
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, arrayOf, shape } = PropTypes;

LandingPageComponent.defaultProps = {
  queryListingsInProgress: false,
  queryListingsError: null,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    queryListingIds,
    queryFeaturedListingIds,
    queryListingsInProgress,
    queryListingsError,
    queryFeaturedListingsInProgress,
    queryFeaturedListingsError,
  } = state.LandingPage;
  const listings = getListingsById(state, queryListingIds);
  const featuredListings = getListingsById(state, queryFeaturedListingIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings,
    queryListingsInProgress,
    queryListingsError,
    featuredListings,
    queryFeaturedListingsInProgress,
    queryFeaturedListingsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
