import React from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { propTypes } from '../../../util/types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ListingCard, NamedLink, IconArrow } from '../../../components';

import css from './SectionListings.module.css';

const MINIMUM_LISTING_COUNT = 0;

const SectionListings = props => {
  const { rootClassName, className, queryListingsInProgress, queryListingsError } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Remove SectionListings if querying listings is in
  // progress, of if there's any error.
  if (queryListingsInProgress || queryListingsError) {
    return null;
  }

  const listings = props.listings
    .filter(l => !l.attributes.publicData.featured)
  const hasListings = listings.length > MINIMUM_LISTING_COUNT;


  return hasListings ? (
    <div className={classes}>
      <div className={css.header}>
        <div className={css.title}>
          <FormattedMessage id="SectionListings.title" />
        </div>
        <NamedLink className={css.exploreLink} name="SearchPage">
          <FormattedMessage id="SectionListings.explore" />
          <IconArrow className={css.exploreIcon} />
        </NamedLink>
      </div>
      <div className={css.listingCards}>
        {listings.map(l => {
          return <ListingCard key={l.id.uuid} listing={l} showAuthorInfo className={css.listingCard} />;
        })}
      </div>

    </div>
  ) : null;
};

SectionListings.defaultProps = {
  rootClassName: null,
  className: null,
  queryListingsInProgress: false,
  queryListingsError: null,
};

SectionListings.propTypes = {
  rootClassName: string,
  className: string,

  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
};

export default SectionListings;
